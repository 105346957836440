/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
export const shouldUpdateScroll = ({ routerProps }) => {
  if (routerProps?.location?.key !== 'initial') {
    window.scrollTo(0, 0);
  }

  return false;
};
