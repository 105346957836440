exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-outage-map-tsx": () => import("./../../../src/pages/outage-map.tsx" /* webpackChunkName: "component---src-pages-outage-map-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-templates-factsheets-and-reports-library-tsx": () => import("./../../../src/templates/factsheetsAndReportsLibrary.tsx" /* webpackChunkName: "component---src-templates-factsheets-and-reports-library-tsx" */),
  "component---src-templates-form-library-tsx": () => import("./../../../src/templates/formLibrary.tsx" /* webpackChunkName: "component---src-templates-form-library-tsx" */),
  "component---src-templates-forms-demand-side-engagement-tsx": () => import("./../../../src/templates/forms/demandSideEngagement.tsx" /* webpackChunkName: "component---src-templates-forms-demand-side-engagement-tsx" */),
  "component---src-templates-forms-general-enquiry-tsx": () => import("./../../../src/templates/forms/generalEnquiry.tsx" /* webpackChunkName: "component---src-templates-forms-general-enquiry-tsx" */),
  "component---src-templates-forms-high-voltage-customer-isolation-tsx": () => import("./../../../src/templates/forms/highVoltageCustomerIsolation.tsx" /* webpackChunkName: "component---src-templates-forms-high-voltage-customer-isolation-tsx" */),
  "component---src-templates-forms-request-to-connect-an-in-home-device-tsx": () => import("./../../../src/templates/forms/RequestToConnectAnInHomeDevice.tsx" /* webpackChunkName: "component---src-templates-forms-request-to-connect-an-in-home-device-tsx" */),
  "component---src-templates-media-centre-tsx": () => import("./../../../src/templates/mediaCentre.tsx" /* webpackChunkName: "component---src-templates-media-centre-tsx" */),
  "component---src-templates-network-upgrades-and-projects-tsx": () => import("./../../../src/templates/networkUpgradesAndProjects.tsx" /* webpackChunkName: "component---src-templates-network-upgrades-and-projects-tsx" */),
  "component---src-templates-news-article-tsx": () => import("./../../../src/templates/newsArticle.tsx" /* webpackChunkName: "component---src-templates-news-article-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */)
}

